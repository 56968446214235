<template src="./details.html"></template>

<script>
import Http from '@/shared/http-config'
import StatusBox from '../components/StatusBox'
import CustomerSearch from '../components/CustomerSearch'
import DeleteCustomer from '../components/DeleteCustomer'
import moment from 'moment'
import store from '../../../Store';
import CustomerStore from '../CustomerStore'
import RegisterStoreModule from '@/shared/registerStoreModule'
import { mapFields } from 'vuex-map-fields';
import {parse} from "@babel/core";

export default {
  name: "Details",
  components: { StatusBox, CustomerSearch, DeleteCustomer },
  mixins: [RegisterStoreModule],
  data: function () {
    return {
      branches: [],
      teachers: [],
      classes: [],
      packets: [],
      new_barcode: '',
      barcodes: [],
      payment_installments: false,
      new_education: {
        class: null,
        package_variation: null,
        package: null,
        start: new Date(),
        passed_talk: false,
      },
    }
  },
  computed: {
    oldSystemUrl () {
      return process.env.VUE_APP_OLD_URL;
    },
    package_variations () {
      return this.$store.getters['customers/get']('package_variations');
    },
    ...mapFields('customers', {
      customer: 'customer',
      customer_payment_dates: 'customer.payment.dates',
      customer_teacher_id: 'customer.teacher.id',
      customer_branch_id: 'customer.branch.id',
      education: 'education',
      educations: 'educations',
      all_educations: 'all_educations',
      packages: 'packages',
    }),
  },
  watch: {
    $route (to, from) {
      if(from.params.education !== to.params.education && from.params.id === to.params.id){
        this.education = this.all_educations.filter(item => item.id === parseInt(to.params.education))[0];
      }
      else if(from.params.id !== to.params.id && parseInt(to.params.id) > 0) {
        this.getEducations();
        this.$store.dispatch('customers/fetchCustomer', this.$route.params.id);
      }
      else if(to.params.id === 'null') {
        this.$store.dispatch('customers/resetCustomer');
      }
    },
    payment_installments: function (value) {
      if(value === false){
        this.customer_payment_dates = [];
      }
    }
  },
  mounted: function(){
    this.$store.commit('set', ['containerFluid', true]);
  },
  destroyed: function(){
    this.$store.commit('set', ['containerFluid', false]);
  },
  beforeRouteEnter: function (to, from, next) {
    Promise.all([
      store.dispatch('fetchBranches'),
      store.dispatch('fetchTeachers'),
      store.dispatch('fetchClasses')
    ]).finally(() => {
      next((vm) => {})
    })
  },
  async created () {
    let self = this;
    self.registerStoreModule('customers', CustomerStore);
    await self.$store.dispatch('customers/fetchStates');

    if(parseInt(self.$route.params.id) > 0){
      await self.$store.dispatch('customers/fetchCustomer', self.$route.params.id);
    }
    else if(self.$route.params.id === 'null') {
      self.$store.dispatch('customers/resetCustomer');
    }

    if(self.customer.payment.dates?.length > 0){
      self.payment_installments = true;
    }

    self.getEducations();
    self.branches = self.$store.getters.get('branches');
    self.teachers = self.$store.getters.get('teachers');
    self.$store.getters.get('classes').forEach(item => {
      self.classes.push({value: item, text: item.name});
    });

    Http.request('GET', '/customers/packets').then((packets) => {
      packets.data.forEach(item => {
        self.packets.push({value: item, text: item.name});
      });
    });
  },
  methods: {
    updatePaymentDates(value, index){
      let customer = this.$store.getters['customers/get']('customer');
      customer.payment.dates[index] = value;
      this.$store.commit('customers/set', ['customer', customer])
    },
    getEducations(select_education = false){
      let self = this;
      if(parseInt(self.$route.params.id) > 0){
        self.$store.dispatch('customers/fetchEducations', self.$route.params.id).then(function(){
          if(self.all_educations.length === 1 && parseInt(self.all_educations[0].id) !== parseInt(self.$route.params.education)){
            self.education = self.all_educations[0];
            self.$router.push('/customers/dashboard/' + self.$route.params.type + '/details/' + self.$route.params.id + '/' + self.all_educations[0].id + '/' + self.$route.name);
          }
          else if(select_education.id > 0){
            self.$router.push('/customers/dashboard/' + self.$route.params.type + '/details/' + self.$route.params.id + '/' + select_education.id + '/' + self.$route.name);
          }
          else if(parseInt(self.$route.params.education) > 0){
            self.education = self.all_educations.filter(item => item.id === parseInt(self.$route.params.education))[0];
          }
          else if(self.education.id === 0 && self.all_educations.length > 0){
            self.$router.push('/customers/dashboard/' + self.$route.params.type + '/details/' + self.$route.params.id + '/' + self.all_educations[0].id + '/' + self.$route.name);
          }
        });
      }
    },
    getCustomerBarcodes(){
      let self = this;
      Http.request('GET', '/customers/' + self.$route.params.id + '/barcodes').then((barcodes) => {
        self.barcodes = barcodes.data;
      });
    },
    saveCustomerBarcode(){
      let self = this;
      Http.request('POST', '/customers/' + self.$route.params.id + '/barcodes', {barcode: self.new_barcode}).then((barcodes) => {
        self.barcodes.push(barcodes.data);
        self.new_barcode = '';
      });
    },
    saveEducation(download = true){
      let self = this;
      let formData = {};
      if(self.new_education.class !== null){
        formData.class = {};
        formData.class.id = self.new_education.class.id;
      }
      else if (self.new_education.package && self.new_education.package.id > 0) {
        if(self.new_education.package_variation === null){
          self.$bvToast.toast('Bitte wählen sie ein ABC Modell aus', {
            title: 'Fehler',
            variant: 'warning',
            autoHideDelay: 5000
          });
          return;
        }
        formData.package = {};
        formData.package.id = self.new_education.package.id;
        formData.package_variation = self.new_education.package_variation;

        formData.class = {};
        formData.class.id = self.new_education.package.classes[0].id;
      }
      else {
        self.$bvToast.toast('Bitte wähle einen Standard-Vertrag oder Vertrag mit ABC-Modell aus.', {
          title: 'Fehler',
          variant: 'warning',
          autoHideDelay: 5000
        });
        return;
      }

      formData.start = moment(self.new_education.start).format('YYYY-MM-DD');
      formData.passed_talk = self.new_education.passed_talk;

      formData.state = self.$store.getters['customers/get']('states')[0];

      self.$store.dispatch('customers/storeCustomer').then(function () {
        Http.request('POST', '/customers/' + self.$route.params.id + '/educations', formData).then((response) => {
          self.getEducations(response.data);
          self.$bvModal.hide('add_education');
          self.$bvToast.toast('Ausbildung gespeichert', {
            title: 'Gespeichert',
            variant: 'success',
            autoHideDelay: 5000
          });
          Object.assign(self.$data.new_education, self.$options.data().new_education);

          if(download) {
            let filename = 'vertrag_' + self.customer.first_name + '_' + self.customer.last_name + '_' + moment().format('DDMMYYYY') + '.pdf';
            Http.requestFile('/customers/' + self.$route.params.id + '/educations/' + response.data.id + '/contract', filename, true);
          }
        });
      });
    },
    customerDeleted(){
      this.$router.push('/customers/dashboard/' + this.$route.params.type)
    },
    deleteEducation(education){
      let self = this;

      let text = '';
      if(education.package && education.package.name){
        text = 'das Programm (' + education.package.name + ' ' + self.package_variations[education.package_variation] + ')';
      }
      else if (education.class){
        text = 'die Klasse (' + education.class.name + ')';
      }

      self.$bvModal.msgBoxConfirm('Möchtest Du ' + text + ' wirklich löschen?', {
        headerBgVariant: 'primary',
        title: 'Klasse löschen?',
        okTitle: 'Ja',
        cancelTitle: 'Nein',
        cancelVariant: 'outline-danger'
      })
      .then(value => {
        if(value === true){
          Http.request('DELETE', '/customers/' + self.$route.params.id + '/educations/' + education.id).then(function (response) {
            self.$store.dispatch('customers/fetchEducations', self.$route.params.id).then(function(){
              if(self.all_educations.length > 0){
                self.education = self.all_educations.filter(item => item.id === self.all_educations[0].id)[0];
                self.$router.push('/customers/dashboard/' + self.$route.params.type + '/details/' + self.$route.params.id + '/' + self.all_educations[0].id + '/' + self.$route.name);
              }
            });
          });
        }
      });
    },
  }
}
</script>

<style scoped></style>
